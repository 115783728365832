<template>
    <setting-layout :title="$t('pages.email_verify.title')">
        <v-container class="EmailVerify">
            <v-row justify="center" align="center">
                <div class="EmailVerify-icon">
                    <email-opened/>
                </div>
            </v-row>
            <v-col v-if="loading" text-center>
                <v-progress-circular
                    indeterminate
                    color="orange"
                ></v-progress-circular>
            </v-col>
            <v-row v-if="!loading" justify="center" align="center">
                <div class="EmailVerify-almostThere">
                    {{ $t('pages.email_verify.almost_there') }}
                </div>
            </v-row>
            <v-row v-if="!loading" justify="center" align="center" no-gutters>
                <div class="EmailVerify-description">
                    {{ $t('pages.email_verify.before_link', {user: user.name}) }}
                    <div class='email'>{{ user.email }}<router-link class="link" to="/user/setting/email">{{ $t('pages.email_verify.change') }}</router-link></div>
                    {{ $t('pages.email_verify.after_link') }}
                </div>
            </v-row>
            <v-row>
                <div class="EmailVerify-button">
                    <btn-large
                        class="btn-accent"
                        :loading="loading"
                        :text="$t('pages.email_verify.button')"
                        @click="handleResendEmail"
                    />
                </div>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout"
import EmailOpened from "@/assets/images/icons/email-opened.svg?inline"

import {mapActions, mapState} from 'vuex'

export default {
    name: "email-verify",
    data() {
        return {
            loading: false,
            params: {
                id: this.$route.query.id || '',
                hash: this.$route.query.hash || '',
                expires: this.$route.query.expires || '',
                signature: this.$route.query.signature || '',
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        })
    },
    watch: {
      user() {
          if (this.user.email_verified) {
              this.$router.push({name: 'auth.home'})
          }
      }
    },
    methods: {
        ...mapActions([
            'EmailVerify',
            'getCurrentUserData',
        ]),
        handleResendEmail() {
            this.EmailVerify().then((resp) => {
                console.log(resp)
            }, () => {
                this.loading = false
            })
        },
    },
    mounted() {
        this.getCurrentUserData()
        if (this.params.id) {
            this.loading = true
            this.EmailVerify(this.params).then(() => {
                localStorage.setItem('email_verified', 'true')
                this.loading = false
                this.$router.push({name: 'user.onboard'})
            }, () => {
                this.loading = false
            })
        }
    },
    components: {
        SettingLayout,
        EmailOpened,
    }
}
</script>

<style lang="scss" scoped>
.EmailVerify {
    &-icon {
        margin-top: 28px;
    }

    &-almostThere {
        font-size: 20px;
        margin-top: 38px;
    }

    &-description {
        font-size: 16px;
        text-align: center;
        width: 100%;
        margin-bottom: 43px;
        margin-top: 20px;

        .email {
            color: #FD7117;
            display: block;
            width: 100%;
            font-size: 18px;

            .link {
                font-size: 12px;
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }

    &-button {
        display: block;
        margin: 0 auto;
        max-width: 375px !important;
        width: 100%;
    }
}
</style>
